import React, { useState, useEffect, useMemo } from "react"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"

import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"
import {
  Title,
  SubTitle,
  Columns,
  Column,
  Button,
} from "../../StyleComponents/styles"
import { FlexDiv } from "../../StyleComponents/pagesStyle"

const GoogleAds = React.lazy(() => import("../../components/ADS/ADS"))
// import GoogleAds from "../../components/ADS"

function RandomNameGenerator(props) {
  const { node } = props.pageContext
  // console.log(data.allNamesListGeneratorJson)
  let [result1, setresult1] = useState([])

  useEffect(() => {
    generate()
  }, [])

  function generate() {
    randomizer(node.num1, node.num2, node.num3)
  }
  function randomizer(num1, num2, num3) {
    let NamesData = []
    for (let i = 0; i < 10; i++) {
      if (i < 6) {
        let random1 = Math.floor(Math.random() * num1.length)
        NamesData.push(`Operation ${num1[random1]}`)
      } else {
        let random2 = Math.floor(Math.random() * num2.length)
        let random3 = Math.floor(Math.random() * num3.length)
        NamesData.push(`Operation ${num2[random2]} ${num3[random3]}`)
      }
    }
    setresult1(NamesData)
  }
  return (
    <Layout location={props.location}>
      <SEO
        title={`Random Military ${node.name} Generator`}
        description={`Random ${node.name} Generator, Generate Random military ${node.name} just on click generate, Simple wee help you find the perfect ${node.name}.`}
        keywords={[
          `${node.name} generator, ${node.name} generator, random ${node.name} generator,${node.name} military name generator,military ${node.name} name generator, random ${node.name}, generate random ${node.name}, random military ${node.name}`,
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Generator", link: "/generator/" },
            { name: `${node.name} Generator`, link: `/${node.path}/` },
          ]
        }, [node.name, node.path])}
      />
      <section className="container">
        <Title>{node.name} generator</Title>
        <SubTitle>Random {node.name} Generator</SubTitle>
        <br />
        <Columns>
          <Column>
            <FlexDiv textAlign="center" minHeight="400px">
              <Button
                className="mb-3"
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                fontSize="1.5rem"
                value="Generate"
                onClick={generate}
              >
                Generate
              </Button>

              <br />
              <div>
                {result1.map((resu, index) => {
                  return (
                    <p className="h5" key={index}>
                      {resu}
                    </p>
                  )
                })}
              </div>
            </FlexDiv>
          </Column>
          <Column>
            {/* google ads UnitPediaRight */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>
        <br />
        <FlexDiv maxWidth="250px" margin="auto">
          <Img
            fluid={node.img.childImageSharp.fluid}
            alt={`random ${node.name} generator`}
          />
        </FlexDiv>
        <br />
        <div>
          <h3>Random Military {node.name} Generator</h3>
          <p>
            Simply press on the button "generate" to generate a random Military{" "}
            {node.name}, wee help you to find the perfect Military {node.name}
          </p>
        </div>
        <br />
        <SeeAlso seeAlsoList={node.SeeAlso} />
        <br />
      </section>
    </Layout>
  )
}

export default RandomNameGenerator
